.footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: $background-color;
    color: $primary-color;
    padding: 40px;
    gap: 20px;
    position: relative;
    // width: 100%;

    /* When there's not enough content, stick to the bottom */
    &.fixed {
        position: fixed;
        bottom: 0;
        left: 0;
    }
  
    .footer-column {
      flex: 1 1 30%; /* Each column takes up ~30% of the space */
      min-width: 200px;
  
      h3 {
        font-family: 'TheSeasons', sans-serif;
        font-size: 1.3rem;
        margin-bottom: 10px;
        .dash {
          font-family: Arial, sans-serif;  
        }
      }
  
      p {
        font-family: 'Quattrocento', serif;
        font-size: 0.9rem;
        margin-bottom: 20px;
      }
  
      ul {
        list-style: none;
        padding: 0;
  
        li {
          margin-bottom: 10px;
  
          a {
            color: $primary-color;
            text-decoration: none;
            font-size: inherit;
            transition: color 0.3s;
  
            &:hover {
              color: $secondary-color;
            }
          }
        }
      }
  
      .social-media {
        display: flex;
        gap: 15px;
  
        a {
          img {
            width: 30px;
            height: 30px;
          }
          &:hover{
            transform: scale(1.1);
          }
        }
      }
  
      form {
        display: flex;
        gap: 10px;
  
        input {
          flex: 1;
          padding: 10px;
          border-radius: 5px;
          border: none;
        }
  
        button {
          padding: 10px 20px;
          background-color: $primary-color;
          color: $background-color;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.3s;
  
          &:hover {
            background-color: $secondary-color;
            transform: scale(1.1)
          }
        }
      }
    }
  }

  .newsletter-form {
    position: relative; // Needed for absolute positioning of the tooltip
  
    .custom-tooltip.success-tooltip {
      position: absolute;
      bottom: -30px; // Adjust position below the button
      left: 50%;
      transform: translateX(-50%) translateY(0);
      background-color: rgba(255, 223, 186, 0.9);
      color: $secondary-color;
      font-family: $font-family-secondary;
      font-size: 0.9rem;
      padding: 5px 8px;
      border-radius: 20px;
      white-space: nowrap;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.6);
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3s ease, transform 0.3s ease;
      z-index: 5;
    }
    // auto hide
  }
  
  