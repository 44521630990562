.toolbar {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    position: relative;

    .filter-button,
    .sort-button {
        background: none;
        border: none;
        font-family: 'Arimo', sans-serif;
        font-size: 1.2rem;
        color: $primary-color;
        cursor: pointer;
        transition: color 0.3s ease;
        display: flex;
        align-items: center;

        &:hover {
            color: $secondary-color;
        }

        .drop-down-icon {
        margin-left: 8px;
        width: 12px;
        height: 12px;
        fill: currentColor; /* Ensures the icon matches text color */
        transition: transform 0.3s ease;

            /* Rotate the icon when the menu is open */
            .sort-button.active & {
                transform: rotate(180deg);
            }
        }
    }

    .sort-button {
        position: absolute;
        right: 0; /* Moves the Sort button to the right edge of the page */
    }
}

.filter-menu {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 300px;
    background-color: $background-color;
    box-shadow: -4px 0 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    padding-top: 50px;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    color: $primary-color;
    overflow-y: auto;
    animation: slide-in-right 0.3s ease-out forwards;
  
    h3 {
      margin-bottom: 20px;
      font-size: 1.5rem;
      font-family: $font-family-secondary;
    }
  
    ul {
      list-style: none;
      padding: 0;
  
      li {
        padding: 15px 0;
        box-shadow: 0 1px rgba(0, 0, 0, 0.1);
        font-size: 1.3rem;
        cursor: pointer;
  
        &:hover {
          color: $secondary-color;
        }
      }
    }

    .close-icon {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 30px;
        height: 30px;
        cursor: pointer;
        transition: transform 0.3s ease;
      
        &:hover {
          transform: scale(1.2);
        }
      }
  
    .results-button {
      margin-top: 20px;
      padding: 10px 20px;
      background-color: $primary-color;
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: $secondary-color;
      }
    }
    .clear-filters-button {
        background-color: transparent;
        margin-top: 20px;
        padding: 10px 20px;
        color: $primary-color;
        border: none;
        text-decoration: dashed;
        border-radius: 5px;
        cursor: pointer;
        transition: color 0.3s ease;
        &:hover {
            color: $secondary-color;
        }
    
    }
  }
  
  /* Sort Menu (Dropdown) */
  .sort-menu {
    position: absolute;
    top: 35px;
    right: 0;
    width: 200px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px;
    z-index: 1001;
    animation: fade-in 0.3s ease-out;
  
    ul {
      list-style: none;
      padding: 0;
  
      li {
        padding: 10px;
        font-family: 'Arimo', sans-serif;
        font-size: 1rem;
        color: $primary-color;
        cursor: pointer;
        transition: background-color 0.2s ease;
  
        &:hover {
          background-color: #f9f9f9;
        }
      }
    }
  }
  

.product-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    padding: 20px;
    margin: 0 auto; // Center the grid
    margin-left: 100px;
    transform: translateY(20px);
    animation: fade-slide-in 0.6s ease-out forwards;
  }
  
  .product-card {
    background-color: #ffdbbb;
    // border: 1px solid $primary-color;
    // border-radius: 10px;
    width: 250px;
    height: 300px;
    padding: 15px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transform: scale(0.9);
    animation: fade-in-scale 0.5s ease-out forwards;
    
    .product-card.new {
      animation: fade-in-scale 0.5s ease-out forwards;
    }
  
    h3 {
      font-family: 'TheSeasons', sans-serif;
      color: $primary-color;
    }
  
    p {
      font-family: 'Quattrocento', serif;
      color: $secondary-color;
    }
  }
  

  
  .pagination {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0;
  
    .products-count {
      font-family: 'Arimo', sans-serif;
      font-size: 1.2rem;
      color: $primary-color;
      margin-bottom: 10px;
    }
  
    .view-more-button {
      background-color: $primary-color;
      color: #fff;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      font-family: 'Arimo', sans-serif;
      font-size: 1rem;
      cursor: pointer;
      margin-bottom: 15px;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: $secondary-color;
      }
    }
  
    .page-breadcrumb {
      display: flex;
      gap: 10px;
      margin-top: 10px;
  
      .page-number {
        background-color: #fff;
        color: $primary-color;
        padding: 8px 12px;
        border-radius: 50%;
        border: 1px solid $primary-color;
        font-family: 'Arimo', sans-serif;
        font-size: 0.9rem;
        cursor: pointer;
        transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;
  
        &.active {
          background-color: $secondary-color;
          color: #fff;
          transform: scale(1.1);
        }
  
        &:hover {
          background-color: $secondary-color;
          color: #fff;
          transform: scale(1.05);
        }
      }
    }
  }

/* Animations */
@keyframes slide-in-right {
    from {
      right: -400px;
    }
    to {
      right: 0;
    }
}
  
  @keyframes fade-in {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
}
    
// Fade & Slide In for initial grid
@keyframes fade-slide-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

// Fade & Scale-In for View More button
@keyframes fade-in-scale {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
    