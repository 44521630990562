@import 'variables';

.review-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('../Assets/Background.png');    
  text-align: center;
  height: 100vh;
  padding: 20px;
  margin-bottom: 0px;
  
  .review-page-title {
    font-family: 'TheSeasons', sans-serif;
    font-size: 2.5rem;
    color: $primary-color;
    margin-bottom: 10px;
    animation: fadeIn 1s ease-in-out;
  }

  .sparkle-icon {
      width: 50px;
      height: auto;
      animation: float 2s infinite ease-in-out;
  }
  
  .sparkle-left {
      margin-right: 10px;
      animation-delay: 0s;
  }
  
  .sparkle-right {
      margin-left: 10px;
      animation-delay: 0.5s;
  }

  .review-page-text {
    font-family: 'Quattrocento', serif;
    font-size: 1.2rem;
    color: $secondary-color;
    max-width: 600px;
    margin-bottom: 20px;
    animation: fadeIn 1.5s ease-in-out;
  }

  .review-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    animation: fadeIn 2s ease-in-out;
    width: 100%;
    max-width: 500px;
    
    textarea {
      width: 100%;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid $primary-color;
      font-size: 1rem;
      font-family: 'Arimo', sans-serif;
      resize: none;
      background-color: #fef5c1;
    }

    button {
      background-color: $secondary-color;
      color: #fff;
      border: none;
      font-size: 1rem;
      padding: 10px 15px;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      font-family: 'TheSeasons', sans-serif;

      &:hover {
        background-color: $primary-color;
        transform: scale(1.05);
      }
    }
  }
}

// Reuse animations
@keyframes fadeIn {
  from {
      opacity: 0;
      transform: translateY(-10px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes float {
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
}
