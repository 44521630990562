@import 'variables';
@import 'fonts';
@import 'taskbar';
@import 'hero';
@import 'footer';
@import 'new';
@import 'shop';
@import 'coming_soon';
@import 'about';
@import 'blog';
@import 'reviews';  
@import 'product-grid';
@import 'dresses';


body {
    background-image: url('../Assets/Background.png');
    background-size: cover;
    background-attachment: fixed;
    font-family: 'Quattrocento', sans-serif;
    color: $background-color;
  }
  main {
    margin-top: 500px; /* Adjust to match the taskbar height */
  }
  
  a {
    outline: none;
    color: $primary-color;
    text-decoration: none;
    font-weight: bold;
    display: inline-block; /* Ensures transform works properly */
    transition: transform 0.2s ease, color 0.2s ease; /* Smooth transition for growth */
  
  }
  a:hover{
    outline: none;
    color: $secondary-color; /* Changes color when hovered */
    transform: scale(1.0); /* Slightly enlarges the link */
  }
  a:active {
    outline: none;
    color: #ff3616; /* Changes to orange when clicked */
  }


  