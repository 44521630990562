$primary-color: #b70200;
$secondary-color: #ff3616;
$background-color: #ffcea5;
$font-family-primary: 'TheSeasons', sans-serif;
$font-family-secondary: 'Quattrocento', 'Arimo', sans-serif;

/* Spacing */
$padding-small: 10px;
$padding-medium: 20px;
$padding-large: 50px;
