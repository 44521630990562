@import 'variables';

.about-section {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: $padding-large;
    // background-color: $background-color;

    .about-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: $padding-large;
    }

    .about-img {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 500px;
            height: 500px;
            object-fit: cover;
            border-radius: 50%;
            border: 5px solid $secondary-color;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
            animation: panIn 1s ease-out;
        }
    }

    .about-txt-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 15px;
        font-family: $font-family-secondary;
        color: $primary-color;
    }

    .about-title {
        font-family: 'TheSeasons', serif;
        font-size: 3.5rem;
        color: $primary-color;
        margin: 0;
        margin-top: 15%;
    }

    .quote-container {
        display: flex;
        align-items: flex-start;
        gap: 15px;
        font-style: italic;

        .quote-line {
            width: 4px;
            height: 100%;
            background-color: $secondary-color;
        }

        .founder-quote {
            max-width: 500px;
            font-size: 1.2rem;
            line-height: 1.6;
            position: relative;

            .signature {
                display: block;
                margin-top: 5px;
                font-style: normal;
                font-weight: bold;
                font-family: 'TheSeasons', serif;
            }
        }
    }

    .mission-paragraph {
        // float: right;
        font-size: 1rem;
        line-height: 1.6;
        margin-top: 10px;
        span{
            font-weight: bold;
        }
    }
}

.partners-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px; /* Same height as banner */
    background-image: url('../Assets/collage_1.jpg'); 
    background-size: cover;
    background-position: center;
    color: #ffcea5; /* Readability over image */
    text-align: center;
    margin-top: 50px;

    .partners-text {
        background-color: $secondary-color;
        text-align: center;
        padding: $padding-medium;
        border-radius: 20px;
        font-family: $font-family-secondary;
        max-width: 600px;

        opacity: 0;
        transform: translateY(50px);
        transition: opacity 1s ease-out, transform 1s ease-out;

        &.visible {
            opacity: 1;
            transform: translateY(0);
        }

        h2 {
            font-size: 2.5rem;
            margin-bottom: 10px;
            color: $background-color;
            font-family: 'TheSeasons', serif;
        }

        p {
            font-size: 1.2rem;
            font-family: $font-family-secondary;
            color: $background-color;
            font-weight: bold;
        }
    }
}

.brand-feature-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 10%;
    text-align: center;

    h3 {
        font-family: 'TheSeasons', serif;
        font-size: 2.5rem;
        color: $primary-color;
        margin-bottom: 15px;
    }

    p {
        font-family: $font-family-secondary;
        font-size: 1.2rem;
        color: $secondary-color;
        margin-bottom: 20px;
        font-weight: bold;
    }

    form {
        width: 100%;
        max-width: 600px;
        display: flex;
        flex-direction: column;
        gap: 15px;

        textarea {
            width: 100%;
            height: 120px;
            padding: 10px;
            background-color: #fef5c1;
            font-family: $font-family-secondary;
            font-size: 1rem;
            border: 2px solid $primary-color;
            border-radius: 5px;
            resize: none;
            outline: none;
            transition: border-color 0.3s ease;

            &:focus {
                border-color: $secondary-color;
            }
        }
        input {
            background-color: #fef5c1;
            font-family: $font-family-secondary;
            font-size: 1rem;
            border: 2px solid $primary-color;
            border-radius: 5px;
            resize: none;
            outline: none;
            transition: border-color 0.3s ease;

            &:focus {
                border-color: $secondary-color;
            }
        }

        button {
            background-color: $secondary-color;
            color: #fff;
            font-family: 'TheSeasons', serif;
            font-size: 1.2rem;
            padding: 12px 20px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s ease, transform 0.2s ease;

            &:hover {
                background-color: $primary-color;
                transform: scale(1.05);
            }
        }
    }
}

.faq-section {
    padding: 50px 10%;
    text-align: center;
    position: relative;

    &::before {
        content: '';
        display: block;
        width: 60%;
        height: 2px;
        background-color: $primary-color;
        margin: 0 auto 30px; // Centered & with space below it
        opacity: 0.6;
    }

    h3 {
        font-family: 'TheSeasons', serif;
        font-size: 2.5rem;
        color: $primary-color;
        margin-bottom: 20px;
    }

    .faq-item {
        margin-bottom: 20px;
        text-align: left;

        h4 {
            font-family: $font-family-secondary;
            font-size: 1.4rem;
            color: $primary-color;
            margin-bottom: 5px;
        }

        p {
            font-family: $font-family-secondary;
            font-size: 1rem;
            color: $secondary-color;
            font-weight: bolder;
        }
        #p-bold{
            font-weight: bold;
            color: $primary-color;
        }

        .review-icon{
            height: 30px;
            width: 30px;
            margin: 0;
            padding-right: 10px;
        }
    }
}




// Sparkle animation to match SunsetAve vibe
@keyframes sparkleFade {
    from { opacity: 0.5; }
    to { opacity: 1; }
}

// Reuse panIn animation from Hero if you want:
@keyframes panIn {
    from {
        opacity: 0;
        transform: translateX(-100px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
