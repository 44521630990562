@import 'variables';

.hero {
    position: relative; /* Add this to define the positioning context for .coming-soon */
    text-align: center; /* Center-align text */
    height: 100vh; /* Optional: Ensures it takes the full viewport height */
    display: flex;
    align-items: center;
    justify-content: center;
}
