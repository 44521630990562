@import 'variables';
  
  /* Logo */
  .logo-container {
    flex: 1;
    display: inline-flex;
    align-items: center;
    position: fixed;
    z-index: 1;
    margin-top: 50px;
    left: 0; 
  }

  .logo {
    height: 250px;
    width: auto;
    transition: opacity 0.3s ease;
  }

  .logo-text {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 70px;
    color: $primary-color; /* Match the branding color */
    opacity: 0; /* Hidden by default */
    transition: opacity 0.3s ease; /* Smooth fade-in */
    background-color: inherit;
  }

  .sunset {
    font-family: 'Eyesome-script','Montserrat', sans-serif;
    font-size: 30px;
    color: #dd2828; /* Custom color for "Sunset" */
  }
  
  .ave {
    font-family: 'DejaVu-serif','Arimo', serif;
    font-size: 20px;
    color: #dd2828; /* Custom color for "Ave" */
    margin-left: 5px; /* Optional spacing between the words */
    margin-right: 20px;
  }

  /* Taskbar Section */
  .taskbar {
    height: 80px;
    width: 100%;
    display: flex;
    margin-left: 261px;
    justify-content: space-between; /* Spreads logo, links, and icons */
    align-items: center; /* Centers items vertically */
    padding: 0px 1px;
    background-color: transparent; /* Transparent taskbar */
    position: fixed;
    top: 0;
    z-index: 1000;
    transition: background-color 0.3s ease; /* Smooth background transition */

    &.scrolled {
      background-color: $background-color;
      box-shadow: 0px 1px rgba(0, 0, 0, 0.1);
    }

    &.expanded {
      height: 100px;
    }
  }  

/* Scrolled State */
.taskbar.scrolled .logo {
  display: none; /* Hides the logo when scrolled */
  padding: 10px 20px;
}

.taskbar.scrolled .logo-text {
  transition: background-color 0.3s ease; /* Smooth background transition */
  opacity: 1; /* Makes the text visible */
  align-items: center;
  background-color: $background-color;
  padding: 29px 40px 7px 60px; /* top right bottom left */
  box-shadow: 0 1px rgba(0, 0, 0, 0.1);
}

  .taskbar.scrolled {
    background-color: $background-color; /* Light background when scrolled */
  }
  
  /* Navigation Links */
  .nav-links {
    flex: 2;
    display: inline-flex;
    gap: 40px;
  }
  
  .nav-links a {
    font-family: 'Arimo', sans-serif;
    font-size: 18px;
    color: $primary-color; /* Default color */
    /* margin-top: 15px; */
    text-decoration: none;
    transition: transform 0.2s ease, color 0.2s ease;
    justify-content: space-between;
  }
  
  .nav-links a:hover {
    color: $secondary-color; /* Hover color */
    transform: scale(1.1); /* Grow effect */
  }

  /* Icons */
  .icons {
    display:inline-flex;
    cursor: pointer;
    gap: 5px;
    margin-right: 23%;

    position: relative;
  }
  
  .review-icon, .search-icon {
    font-size: 24px; /* icons are 33x33px */
    color: $primary-color;
    padding-left: 15px;
    transition: transform 0.2s ease, color 0.2s ease;
    &:hover {
      color: $secondary-color;
      transform: scale(1.1);
    }
  }
  
  .review-container {
    position: relative;
    display: inline-block;

    .custom-tooltip {
      position: absolute;
      bottom: -30px; // Adjust position
      left: 50%;
      transform: translateX(-50%);
      background-color: rgba(255, 223, 186, 0.9);
      color: $secondary-color; 
      font-family: 'TheSeasons', sans-serif;
      font-size: 0.9rem;
      padding: 5px 5px;
      border-radius: 20px;
      white-space: nowrap;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease, transform 0.3s ease;
    }
  
    &:hover .custom-tooltip {
      opacity: 1;
      visibility: visible;
      transform: translateX(-50%) translateY(-5px);
    }
  }

  .taskbar.expanded {
    height: 100px;
}

  // Search Container
  .search-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: auto;
    transition: all 0.3s ease-in-out;
}

// Search Input
.search-input {
  position: absolute;
  right: 40px; // Positions input left of the icon
  width: 0;
  opacity: 0;
  border: none;
  padding: 5px 10px;
  font-size: 16px;
  transition: width 0.4s ease-in-out, opacity 0.4s ease-in-out;
  outline: none;
  background-color: transparent;
  border-bottom: 2px solid transparent;
}

// Expand when focused
.search-input.expanded {
  width: 180px;
  opacity: 1;
  border-bottom: 2px solid $primary-color;
}

// Search Icon
.search-icon {
    width: 30px;
    height: auto;
    // margin-left: 10px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
}

// Keep icon in place when expanded
.search-container.expanded .search-icon {
  transform: translateX(0);
}

// Fade-in Animation for Search Bar
@keyframes fade-in-search {
  from {
      opacity: 0;
      transform: translateY(-5px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}


  