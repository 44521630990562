@import 'variables';

.dresses-page-banner {
    margin-top: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 130px; 
    background-image: url('../Assets/orange_collage_1.jpg'); 
    background-size: cover;
    opacity: 80%;
    background-position: center;
    color: #ffcea5; /* Adjust for readability */
    text-align: center;
    padding-bottom: 0%;
    margin-bottom: 0;

    .dresses-page-banner-content {
        padding: 20px;
        font-family: $font-family-secondary;
      }
    
      h2 {
        font-size: 2.5rem;
        margin-bottom: 10px;
      }  
}