.coming-soon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url('../Assets/Background.png');    
    text-align: center;
    height: 100vh;
    // padding: 20px;
    padding-top: 0px;
    margin-bottom: 0px;
    
    .coming-soon-title {
      font-family: 'TheSeasons', sans-serif;
      font-size: 2.5rem;
      color: $primary-color;
      margin-bottom: 10px;
      animation: fadeIn 1s ease-in-out;
    }

    .sparkle-icon {
        width: 50px; // Adjust size to your preference
        height: auto;
        animation: float 2s infinite ease-in-out;
      }
      
      // Left sparkle animation
      .sparkle-left {
        margin-right: 10px;
        animation-delay: 0s; // Starts immediately
      }
      
      // Right sparkle animation
      .sparkle-right {
        margin-left: 10px;
        animation-delay: 0.5s; // Staggered for a cool effect
      }
  
    .coming-soon-text {
      font-family: 'Quattrocento', serif;
      font-size: 1.2rem;
      color: $secondary-color;
      max-width: 500px;
      margin-bottom: 20px;
      animation: fadeIn 1.5s ease-in-out;
    }

    .newsletter-form {
      display: flex;
      gap: 10px;
      animation: fadeIn 2s ease-in-out;
  
      input {
        padding: 10px;
        border-radius: 5px;
        border: 1px solid $primary-color;
        font-size: 1rem;
        width: 250px;
      }
  
      button {
        background-color: $primary-color;
        color: #fff;
        border: none;
        font-size: 1rem;
        padding: 10px 15px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
  
        &:hover {
          background-color: $secondary-color;
          transform: scale(1.05);
        }
      }
    }
  }
  
  /* Soft fade-in animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  // Floating animation
  @keyframes float {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
  }
  