@font-face {
    font-family: 'TheSeasons';
    src: url('../Assets/fonts/the-seasons/Fontspring-DEMO-theseasons-bd.otf') format('truetype'), 
         url('../Assets/fonts/the-seasons/Fontspring-DEMO-theseasons-bdit.otf') format('truetype'), 
         url('../Assets/fonts/the-seasons/Fontspring-DEMO-theseasons-it.otf') format('truetype');
    font-weight: normal;
    font-style: normal;  
  }
  @font-face {
    font-family: 'TheSeasons-Bd-It';
    src: url('../Assets/fonts/the-seasons/Fontspring-DEMO-theseasons-bdit.otf') format('truetype');
    font-weight: bold;
    font-style: italic;  
  }
  
  @font-face {
    font-family: 'TheSeasons-It';
    src: url('../Assets/fonts/the-seasons/Fontspring-DEMO-theseasons-it.otf') format('truetype');
    font-weight: normal;
    font-style: italic;  
  }
  
  @font-face {
    font-family: 'Eyesome-script';
    src: url('../Assets/fonts/Eyesome/Eyesome\ Script.otf') format('truetype');
    font-weight: normal;
    font-style: normal;  
  }
  
  @font-face {
    font-family: 'DejaVu-serif';
    src: url('../Assets/fonts/dejavu-serif/DejaVuSerif.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;  
  }

  @font-face {
    font-family: 'Gladolia-reg';
    src: url('../Assets/fonts/gladolia-demo/GladoliaDEMO-Regular.otf') format('truetype');
    font-weight: normal;
    font-style: normal;  
  }

  