@import 'variables';

.new-page {
    // padding-top: 100px;

    .new-page-banner {
        margin-top: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 130px; 
        background-image: url('../Assets/orange_collage_1.jpg'); 
        background-size: cover;
        opacity: 80%;
        background-position: center;
        color: #ffcea5; /* Adjust for readability */
        text-align: center;

        .new-page-banner-content {
            // background-color: $secondary-color;
            padding: 20px;
            // border-radius: 10px;
            font-family: $font-family-secondary;
          }
        
          h2 {
            font-size: 2.5rem;
            margin-bottom: 10px;
          }         
    
    }
    .join-newsletter{
        color: $primary-color;
        display: flex;
        flex-direction: column;
        align-items: center; /* Centers content horizontally */
        justify-content: center; /* Centers content vertically */
        padding: 10px;
        margin: 20px auto;
        max-width: 600px; /* Controls the width of the container */
        
        .coming-soon-title {
            font-family: 'TheSeasons', sans-serif;
            font-size: 2.5rem;
            color: $primary-color;
            margin-bottom: 10px;
            animation: fadeIn 1s ease-in-out;
      
          }
      
          .sparkle-icon {
              width: 50px; // Adjust size to your preference
              height: auto;
              animation: float 2s infinite ease-in-out;
            }
            
            // Left sparkle animation
            .sparkle-left {
              margin-right: 10px;
              animation-delay: 0s; // Starts immediately
            }
            
            // Right sparkle animation
            .sparkle-right {
              margin-left: 10px;
              animation-delay: 0.5s; // Staggered for a cool effect
            }
    
        p {
            text-align: center;
            font-family: 'Quattrocento', serif;
            font-size: 1.3rem;
            margin-bottom: 20px;
        }    

        form {
            display: flex;
            gap: 10px;
    
            input {
                flex: 1;
                padding: 10px;
                border-radius: 5px;
                border: none;
            }
      
            button {
                padding: 10px 20px;
                background-color: $primary-color;
                color: $background-color;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                transition: background-color 0.3s;
        
                &:hover {
                    background-color: $secondary-color;
                    transform: scale(1.1)
                }
            }
        }
    
    }
  


}

